<template>
    <!-- 查看弹框数据表 -->
    <div id="app">
      <div id="chart1"></div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    // props: ['dataList'],
    data() {
      return {
        dataList:[]
      }
    },
    created() {
    },
    mounted() {
      this.initCharts()
    },
    methods: {
      initCharts() {
  
        var chartDom = document.getElementById('chart1');
        var myChart = echarts.init(chartDom);
        var option;
  
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
            iconStyle: {
              color: "white",
            },
            iconStyle: {
              color: "white",
            },
            feature: {
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          legend: {
            data: ['用水量柱形图', '用水量折线图']
          },
          xAxis: [
            {
              type: 'category',
              data: this.dataList.timeList,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 14,
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '用水量',
              nameTextStyle: {
                color: '#fff'
              },
              interval: 50,
              axisLabel: {
                formatter: '{value} m³',    // 单位
                textStyle: {
                  color: '#fff'
                }
              }
            },
          ],
          series: [
            {
              name: '用水量柱形图',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' m³';
                }
              },
              data: this.dataList.valueList,
              itemStyle: {
                color: '#008c8c'
              }
            },
            {
              name: '用水量折线图',
              type: 'line',
              smooth: true,
              tooltip: {
                valueFormatter: function (value) {
                  return value + ' m³';
                }
              },
              data: this.dataList.valueList,
              itemStyle: {
                color: '#ccc'
              }
            },
          ]
        };
        option && myChart.setOption(option);
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  #chart1 {
    width: 1000px;
    height: 550px;
  }
  </style>