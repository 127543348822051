<template>
  <!-- 设备监控（用电） -->
  <div id="app">
    <div class="container">
      <div class="list">
        <div class="search">
          <el-input placeholder="输入关键字进行搜索" v-model="filterText">
          </el-input>
        </div>
        <div class="tree">
          <el-tree
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            default-expand-all
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="selectDevice"
          >
          </el-tree>
        </div>
      </div>
      <div class="content">
        <dv-border-box-4
          style="padding: 30px 0 0 30px; box-sizing: border-box"
          :color="['#135b85', '#0bced9']"
        >
          <div style="font-size: 21px; color: #1fc6a5">
            <i class="el-icon-view"></i>
            {{ treeSelected.deviceId }}
          </div>
          <dv-decoration-1
            :color="['#067e82', '#067e82']"
            style="width: 400px; height: 20px"
          />
          <!-- 日期选择栏部分 -->
          <div>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-date-picker
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="1.5"><el-button>查询</el-button></el-col>
              <el-col :span="1.5"
                ><el-button @click="dialogVisible = true"
                  >测试弹框</el-button
                ></el-col
              >
            </el-row>
          </div>
          <!-- 表格部分 -->
          <div class="table">
            <el-table :data="tableData" height="100%" style="width: 100%">
              <el-table-column
                label="序号"
                type="index"
                align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column label="设备编号" align="center">
              </el-table-column>
              <el-table-column label="IMEI" align="center"> </el-table-column>
              <el-table-column label="设备名称" align="center">
              </el-table-column>
              <el-table-column label="总用电量" align="center">
              </el-table-column>
              <el-table-column label="操作" align="center"> </el-table-column>
            </el-table>
          </div>
        </dv-border-box-4>
      </div>

      <!-- 自定义弹框部分 -->
      <transition name="el-fade-in">
        <div class="dialog" v-show="dialogVisible">
          <div class="dialog-box">
            <div class="dialog-title">
              <!-- 标题部分，需要拿到设备号，名 -->
              标题
              <!-- 关闭图标 -->
              <i
                class="iconfont icon-iotechguanbi1"
                style="float: right; cursor: pointer"
                @click="dialogVisible = false"
              ></i>
            </div>
            <div class="dialog-data">
              <!-- 在这里导入组件 -->
              <ElectricityUseDialog></ElectricityUseDialog>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
  <script>
import DeviceHistoryValue from "../../../api/manageApi/DeviceHistoryValue";
import ElectricityUseDialog from "../../../components/Electricity/dialog/ElectricityUseDialog.vue";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  components: {
    ElectricityUseDialog
  },
  props: {},
  created() {
    this.getDeviceData();
  },
  data() {
    return {
      dialogVisible: false,
      // 搜索框双向绑定
      filterText: "",
      // 树形图绑定假数据
      treeData: [],
      // 树形图绑定假字段
      defaultProps: {
        children: "children",
        label: "deviceId",
      },
      // 树形图点击事件获取到的数据
      treeSelected: {
        deviceId: "",
        deviceName: "",
      },
      // 表格绑定数据
      tableData: [],
    };
  },
  methods: {
    // tree搜索
    filterNode(value, data) {
      if (!value) return true;
      return data.deviceId.indexOf(value) !== -1;
    },
    // 获取设备列表
    async getDeviceData() {
      let deviceType = "DXElectricMeter";
      const res = await DeviceHistoryValue.getHierarchicalList(deviceType, "");
      console.log(res.data.data.list);
      this.treeData = res.data.data.list;
      // 调用默认选择的方法
      this.defaultView();
    },
    // 树形图点击事件
    selectDevice(val) {
      console.log(val);
      this.treeSelected.deviceId = val.deviceId;
    },
    // 设置进入页面之后的默认显示事件（作为获取数据的回调函数）
    defaultView() {
      // 将首项设为默认展示
      let defaultInfo = this.treeData[0];
      this.selectDevice(defaultInfo);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../../../assets/css/uiCss.scss";
@import "../../../assets/css/tableCss.scss";
#app {
  .container {
    position: relative;
    width: 100%;
    height: 91vh;
    .list {
      position: absolute;
      left: 0;
      height: 100%;
      width: 15%;
      .search {
        padding-top: 15px;
      }
      /deep/.el-tree{
  background-color: rgba(0,0,0,0) !important;
  color:#0ce3eb;
}
    }
    .content {
      position: absolute;
      right: 0;
      height: 100%;
      width: 85%;
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .el-button {
      color: #033744;
      background-color: #397de2;
      border: none;
    }
  }
  .table {
    width: 99%;
    height: 89%;
  }
}
.dialog {
  position: absolute;
  top: -50px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  .dialog-box {
    box-sizing: border-box;
    width: 55%;
    height: 650px;
    background: rgb(75, 96, 128);
    border-radius: 5px;
    margin: 150px auto 0;
    padding: 20px;

    .dialog-title {
      font-size: 22px;
      font-family: "宋体";
      font-weight: 600;
      letter-spacing: 2px;
      color: #0ce3eb;
    }
    .dialog-data {
      box-sizing: border-box;
      width: 100%;
      height: 95%;
      padding: 10px;
      margin: 10px 0;
    }
  }
}

/deep/.container .device-menu .left .tree[data-v-97811b8e]{
  height: 750px !important;
  overflow: auto !important;
}
</style>